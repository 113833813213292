<template>
  <div id="members">
    <div>
      <h1>用户列表</h1>
    </div>
    <el-card class="box-card">
      <div style="width:20%;margin-bottom:20px;">
        <span>
        搜索：
        </span>
        <el-input
            class='right'
            v-model="input"
            size="small"
            placeholder="根据进行搜索.."
            @keyup.enter.native="searchMember">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchMember" style="cursor: pointer"></i>
        </el-input>
      </div>
      <div>
        <div>
          <el-table
              v-loading="loading"
              :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
              border
              :default-sort="{ prop: 'id'}"
              style="width: 100%; font-size: 14px">
            <el-table-column
                prop="id"
                label="ID"
                sortable
                align="center">
            </el-table-column>
            <el-table-column
                prop="mobile"
                label="手机"
                align="center">
            </el-table-column>
            <el-table-column
                prop="valid"
                label="是否可填写问卷"
                align="center">
              <template v-slot="scope">
                <el-checkbox @change="onUpdateValidStatus(scope.row.id, scope.row.valid)" :checked="scope.row.valid"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
                prop="lastLoginTime"
                label="最后登录时间">
            </el-table-column>
            <el-table-column
                prop="report"
                label="测试结果"
            >
            </el-table-column>
            <el-table-column label="操作" width="250" align="center">
              <template slot-scope="scope">
                <div style="display:inline">
                  <el-button type="text" class="el-icon-delete op" style="color: red" @click="delSubmit(scope.row.id, scope.row.num)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination layout="sizes, prev, pager, next, jumper"
                         background
                         :current-page="currentPage"
                         :page-size="pageSize"
                         :page-sizes="pageSizes"
                         :total="tableData.length"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         style="margin: 20px"
                         v-if="!loading">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'UserList',
  data() {
    return {
      input: '',
      currentPage: 1,
      pageSize: 7,
      pageSizes: [3, 5, 7],
      loading: true,
      tableData: [],
    }
  },
  created() {
    this.searchMembers()
  },
  methods: {
    searchMembers(mobile = "") {
      let loadingIns = this.$loading({fullscreen: true, text: '拼命加载中'});
      this.$axios({
        method: 'post',
        url: '/member/list',
        data: {
          mobile,
        },
      })
          .then(res => {
            loadingIns.close();
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              this.loading = false;
            } else {
              this.$message.error("请求失败！");
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    searchMember() {
      this.searchMembers(this.input);
    },
    onUpdateValidStatus: function (id, valid) {
      let loadingIns = this.$loading({fullscreen: true, text: '拼命加载中'});
      this.$axios({
        method: 'post',
        url: '/member/updateValid',
        data: {
          id: id,
          valid: !valid,
        },
      })
          .then(res => {
            loadingIns.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
            } else {
              this.$message.error("请求失败！");
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    delSubmit(memberId, num) {
      this.$confirm('此操作将永久删除该用户并删除他的问卷记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const formData = new FormData();
        formData.append("id", memberId);
        this.$axios({
          method: 'post',
          url: '/member/delete',
          data: formData,
        })
            .then(res => {
              switch (res.data.code) {
                case 0:
                  this.$message.success("删除成功");
                  this.tableData.splice(num-1, 1);
                  for (var i=num+1; i<=this.tableData.length+1; i++)
                    this.tableData[i-2].num -= 1;
                  break;
                default:
                  this.$message.error("执行操作失败: " + res.data.msg);
                  break;
              }
            })
            .catch(err => {
              console.log(err);
            });
      }).catch(() => {
      });
    },
    sort_change(column) { // column是个形参，具体查看element-ui文档
      this.currentPage = 1 // return to the first page after sorting
      this.total = this.tableData.length
      this.tableData = this.tableData.sort(this.sortFun(column.prop, column.order === 'ascending'));
      // this.showedData = this.tableData.slice(0, this.pageSize) // 排序完显示到第一页
    },
    sortFun(attr, rev) {
      //第一个参数传入info里的prop表示排的是哪一列，第二个参数是升还是降排序
      if (rev === undefined) {
        rev = 1;
      } else {
        rev = (rev) ? 1 : -1;
      }
      return function (a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 0;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
  }
}
</script>

<style scoped>
#sum{
  width: 90%;
  /* background-color: cyan; */
}
.op{
  float: right;
  margin: 0 30px;
}
.box-card{
  margin: 20px;
}
.must {
  font-weight: normal;
  color: crimson;
}
.main .el-radio{
  pointer-events: none;
}
.main .el-checkbox{
  pointer-events: none;
}
#dia{
  height: 500px;
  overflow: auto;
}

.dialog .el-divider--horizontal {
  margin: 15px 0;
}

.q-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 40px 10px 10px;
  font-weight: bold;
}
.q-description {
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #969696;
}
.q-opt {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 10px 10px 10px;
}
.el-checkbox {
  padding: 10px 0;
  display: block;
}
.title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 25px;
}

.description {
  text-align: left;
  font-size: 16px;
  color: #969696;
  line-height: 30px;
  padding-bottom: 10px;
}

.block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #969696;
  padding-right: 60px;
  margin-bottom: 20px;
}

.block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  /* color: #e59824; */
  padding-right: 60px;
}

</style>
